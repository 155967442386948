<!-- eslint-disable vue/valid-v-else-if -->
<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>医生姓名：</label>
        <el-input
          style="width:200px"
          size="mini"
          placeholder="请输入医生姓名"
          prefix-icon="el-icon-search"
          v-model="searchParams.doctor_name"
          clearable
          @change="getList('restPage')"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="editDoctor"
            >新增医师
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              v-if="column.column_label_user_definition != '医助'"
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                handleCustomerDetail(scope.row, column.column_prop)
              "
            >
              {{
                column.column_label_user_definition == "就诊设置"
                  ? "问诊价格"
                  : scope.row[column.column_prop]
              }}
            </a>
            <a
              v-if="column.column_label_user_definition == '医助'"
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                handleCustomerDetail(scope.row, column.column_prop)
              "
            >
              {{ scope.row[column.column_prop] == false ? "未绑定" : "已绑定" }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="column.column_label == '名医认证'">
              <el-switch
                v-model="scope.row[column.column_prop]"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="2"
                :inactive-value="1"
                @change="institutionalStatus(scope.row)"
              >
              </el-switch>
            </div>
            <div
              v-else-if="
                $store.state.user.is_admin == 1 &&
                  column.column_label == '医师机构'
              "
            >
              <el-select
                v-model="scope.row.agent_name"
                placeholder="请选择"
                @change="agentChange($event, scope.row)"
              >
                <el-option
                  v-for="item in optionsName"
                  :key="item.agent_code"
                  :label="item.agent_name"
                  :value="item.agent_code"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'upload_name' == column.column_prop"
                style="width: 200px"
                v-model="searchParams.upload_status"
                clearable
                placeholder="请选择"
                @change="handleSearch(column.column_prop, searchParams.mobile)"
                size="small"
              >
                <template v-for="item in filingStatus">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="'status_name' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.mobile)"
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in doctorStatus">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-cascader
                v-if="'depart_name' == column.column_prop"
                v-model="searchParams.depart_id"
                style="width: 200px"
                clearable
                size="small"
                :options="departSymptom"
                @change="handleSearch(column.column_prop, searchParams.mobile)"
                :props="{ checkStrictly: true }"
              >
              </el-cascader>
              <el-select
                v-if="'professionalName' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.mobile)"
                v-model="searchParams.professional"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in doctorDic.doctor_title"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      :append-to-body="true"
      size="76%"
      :visible.sync="dialogTableVisible"
      direction="rtl"
    >
      <physicianInfo
        @closeDrawer="closeDrawer"
        doctorType="1"
        :type="'view'"
        :info="info"
        ref="physicianDetail"
        v-if="dialogTableVisible"
      />
    </el-drawer>
    <!--绑定医助列表-->
    <el-dialog
      title="绑定医助"
      :visible.sync="dialogAssistant"
      v-if="dialogAssistant"
    >
      <div class="" style="display: flex;margin-bottom: 20px;">
        <div class="search-container-fn-input">
          <label>医助姓名：</label>
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入医助姓名"
            prefix-icon="el-icon-search"
            clearable
            @change="_getAssistantList"
            v-model="bindQuery.name"
          >
          </el-input>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="assistants"
        @selection-change="handleSelectionChange1"
        border
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="180">
        </el-table-column>
        <el-table-column
          prop="realname"
          label="医助姓名"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="手机号"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="性别">
          <template slot-scope="{ row }">
            {{ row.gender == 1 ? "男" : "女" }}
          </template>
        </el-table-column>
        <el-table-column prop="agent_name" align="center" label="机构">
        </el-table-column>
      </el-table>
      <pagination
        v-show="bindTotal > 0"
        :total="bindTotal"
        :page.sync="bindQuery.page"
        :limit.sync="bindQuery.limit"
        @pagination="_getAssistantList"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="saveBindDoc">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 问诊价格设置弹出框 -->
    <el-dialog
      v-loading="listLoading"
      :visible.sync="isActiveDialog"
      width="900px"
      inline
      :close-on-click-modal="false"
      :before-close="handleInquiryPriceCancel"
    >
      <div
        slot="title"
        style="
          font-size: 14px;
          color: #606266;
          font-weight: 700;
          margin-bottom: 10px;
        "
      >
        <div class="required"></div>
        <span>问诊价格</span>
      </div>

      <div class="inquiry">
        <div class="inquiryImg m-t-10" style="margin-bottom: 10px">
          <img src="../../assets/graphic.png" />
          <span style="margin-right: 3px">图文复诊</span>
          <el-switch
            v-model="picPrice.pic_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </div>
        <div class="inquiryText m-t-10">
          <span style="margin-right: 3px">首次复诊 ¥</span>
          <el-input
            placeholder="价格"
            size="mini"
            maxlength="6"
            v-model.trim="picPrice.FirstConsultation"
            clearable
          >
          </el-input>
          <span style="margin-left: 5px; margin-right: 3px">常规复诊 ¥</span>
          <el-input
            placeholder="价格"
            size="mini"
            maxlength="6"
            v-model.trim="picPrice.commonConsultation"
            clearable
          >
          </el-input>
          <span style="margin-left: 5px; margin-right: 3px">问诊时长</span>
          <el-input
            placeholder=""
            size="mini"
            maxlength="6"
            v-model.number="picPrice.pic_time"
            clearable
          >
          </el-input>
          <el-select
            class="select-container"
            size="mini"
            v-model="picPrice.pic_time_type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in inquiryTimeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="inquiry" style="margin-top: 20px; margin-bottom: 20px">
        <div class="inquiryImg m-t-10">
          <img src="../../assets/video.png" />
          <span style="margin-right: 3px">视频复诊</span>
          <el-switch
            v-model="videoPrice.video_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </div>
        <div class="inquiryText m-t-10" style="margin-top: 10px">
          <span style="margin-right: 3px">首次复诊 ¥</span>
          <el-input
            placeholder="价格"
            size="mini"
            maxlength="6"
            v-model.trim="videoPrice.FirstConsultation"
            clearable
          >
          </el-input>
          <span style="margin-left: 5px; margin-right: 3px">常规复诊 ¥</span>
          <el-input
            placeholder="价格"
            size="mini"
            maxlength="4"
            v-model.trim="videoPrice.commonConsultation"
            clearable
          >
          </el-input>
          <span style="margin-left: 5px; margin-right: 3px">问诊时长</span>
          <el-input
            placeholder=""
            size="mini"
            maxlength="4"
            v-model.number="videoPrice.video_time"
            clearable
          >
          </el-input>
          <el-select
            size="mini"
            class="select-container"
            v-model="videoPrice.video_time_type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in inquiryTimeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleInquiryPriceCancel"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="submitInquiryPriceForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--设置费率-->
    <el-dialog
      title="设置费率"
      :visible.sync="reviewed"
      v-if="reviewed"
      width="40%"
    >
      <el-form
        ref="ruleForm1"
        :model="form1"
        label-width="110px"
        :rules="rules1"
        class="demo-ruleForm"
      >
        <el-form-item label="医生姓名">
          {{ form1.doctor_name }}
        </el-form-item>
        <el-form-item label="其他补贴费率" prop="pres_rate">
          <el-input v-model="form1.pres_rate" style="width: 70%"></el-input> %
        </el-form-item>
        <!-- <div slot="footer" class="dialog-footer"> -->
        <el-form-item>
          <el-button type="success" size="small" @click="saveRateForm()"
            >确定</el-button
          >
        </el-form-item>

        <!-- </div> -->
      </el-form>
    </el-dialog>
    <el-dialog
      title="修改排序"
      :visible.sync="dialogFormVisible2"
      width="900px"
    >
      <el-form :model="doctorList">
        <el-form-item label="排序" label-width="120">
          <el-input
            v-model="doctorList.show_sort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleQX">取 消</el-button>
        <el-button type="primary" @click="haneldSortSubmit(doctorList)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import Pagination from "@/components/Pagination";
import { getAdminFieldIndex, delPresTake } from "@/api/drugs2.0";
import { getAdminCommonMerchants } from "@/api/finance/index.js";
import {
  getDoctorsDetails,
  physicianTermination,
  getDoctorsListNew,
  postUpdateDiagnosisSetting,
  getDiagnosisSetting,
  setDocRate,
  getAssistants,
  bindAssistant,
  postAgentDoctor, //选择医生机构
} from "@/api/audit/doctor2.0.js";
import physicianInfo from "./physicianInfo";
import { updateDoctorInfo } from "@/api/audit/doctor.js";
import { mapGetters, mapState } from "vuex";
import editTableHead from "@/components/editTableHead/editTableHead";

export default {
  // 在用客户
  name: "doctorManage",
  components: {
    Pagination,
    editTableHead,
    // eslint-disable-next-line vue/no-unused-components
    physicianInfo,
  },
  computed: {
    ...mapGetters(["adminId"]),
    ...mapState({
      is_admin: (state) => state.user.is_admin,
      operates: (state) => state.user.operates,
      filingStatus: (state) => state.dictionary.filingStatus,
      doctorStatus: (state) => state.dictionary.doctorStatus,
      doctorDic: (state) => state.dictionary.doctorDic, //字典
      departSymptom: (state) => state.dictionary.departDic, //字典
      is_self: (state) => state.user.is_self,
      table_options: (state) => state.user.table_options,
    }),
  },
  async created() {
    console.log(11111, this, this.$store.state);
    await this._getAdminFieldIndex();
    this.getList();
    this._getAdminCommonMerchants();
  },
  filters: {
    useStatusFormat: (status) => {
      let result = "";
      // 0 未用 1 在用 2 停用 3 预停
      switch (status) {
        case 0:
          result = "未用";
          break;
        case 1:
          result = "在用";
          break;
        case 2:
          result = "停用";
          break;
        case 3:
          result = "预停";
          break;
      }
      return result;
    },
  },
  data() {
    return {
      table_type:'doctorManage',
      tableHead: [],
      isActiveEditTableHead: false,
      searchTableHead: [
        //控制搜索
        {
          name: "status_name",
          isSearch: false,
        },
        {
          name: "upload_name",
          isSearch: false,
        },
        {
          name: "mobile",
          isSearch: false,
        },
        {
          name: "professionalName",
          isSearch: false,
        },
        {
          name: "depart_name",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      optionsName: [],
      doc_id: 0,
      selectAssistants: [],
      assistantTotal: 0,
      assistants: [],
      dialogAssistant: false,
      doctorList: {},
      dialogFormVisible2: false,
      reviewed: false,
      form1: {},
      info: {},
      showPop: false,
      type: 1, //1--医师,2--药师
      selected: false,
      clearFilter: false,
      dialogTitle: "",
      dialogType: "",
      datetimerange: [],
      currUser: {},
      dialogTableVisible: false,
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: false,
      fullscreenLoading: false,
      bindQuery: {
        page: 1,
        limit: 10,
      },
      bindTotal: 0,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      options: [],
      ruleForm: {
        examine: "",
      },
      rules1: {
        pres_rate: [
          {
            required: true,
            message: "请填写其他补贴费率",
            trigger: "blur",
          },
        ],
      },
      isActiveDialog: false,
      picPrice: {
        FirstConsultation: 0.01,
        commonConsultation: 0.01,
        pic_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
        pic_time: 1,
        pic_status: 1,
      },
      videoPrice: {
        FirstConsultation: 0.01,
        commonConsultation: 0.01,
        video_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
        video_time: 1,
        video_status: 1,
      },
      inquiryTimeOptions: [
        {
          value: 0,
          label: "分钟",
        },
        {
          value: 1,
          label: "时",
        },
        {
          value: 2,
          label: "天",
        },
      ],
      inquiryTimeOptionsValue: "",
      rules: {
        examine: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: "change",
          },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      curDoctor: {},
      operateList: [],
    };
  },
  mounted() {
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "doctorDic",
        api: "/admin/hospital/getDicts",
      },
    ]);
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "departDic",
        api: "/admin/hospital/getDeparts",
      },
    ]);
  },
  methods: {
    //机构选择
    async agentChange(e, row) {
      this.listLoading = true;
      console.log(e, row);
      let item = this.optionsName.find((item) => item.agent_code == e);
      console.log(item);
      let param = {
        agent_code: e,
        agent_name: item.agent_name,
        id: row.doctors_id,
      };
      console.log(param, "================2222");
      const res = await postAgentDoctor(param);
      if (res.code == 200) {
        this.$message({
          message: "设置成功",
          type: "success",
        });
        this.getList();
      } else {
        this.$message.error(res.msg);
        this.listLoading = false;
      }
    },
    handleSelectionChange1(e){
      console.log(e,2222);
      this.selectAssistants = e;
    },
    saveBindDoc() {
      let ids = this.selectAssistants.map((item) => {
        return item.id;
      });
      if (ids.length == 0) {
        return this.$message.error("请选择要绑定的医生！");
      }
      console.log(11111111, ids);
      bindAssistant(this.doc_id, ids).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.getList();
          this.dialogAssistant = false;
        }
      });
    },
    saveRateForm() {
      let params = {
        pres_rate: this.form1.pres_rate,
      };
      setDocRate(this.form1.doctors_id, params).then((res) => {
        this.reviewed = false;
        if (200 == res.code) {
          this.getList();
          return this.$message({
            type: "success",
            message: "设置成功!",
          });
        } else {
          return this.$message.error(res.msg);
        }
      });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    editDoctor() {
      if (this.$store.state.user.level == 0) {
        this.$router.push({
          path: "/credentManage/addDoctor",
          query: {
            type: "1",
            name: "doctor",
          },
        });
      } else {
        this.$router.push({
          path: "/proxyDoctor/proxyAddDoc",
          query: {
            type: "1",
            name: "doctor",
          },
        });
      }
    },
    //机构选择
    _getAdminCommonMerchants() {
      getAdminCommonMerchants().then((res) => {
        if (res.code == 200) {
          let infoList = res.data.map((item) => {
            return {
              agent_id: item.id,
              agent_code: item.agent_code,
              agent_name: item.merchant_name,
            };
          });
          this.optionsName = infoList;
          console.log(this.optionsName);
        }
      });
    },
    //名医认证按钮
    async institutionalStatus(row) {
      console.log(row);
      // var id = row.doctors_id;
      let param = {
        // show_sort = row.show_sort,
        id: row.doctors_id,
        doctor_type: row.doctor_type,
      };
      const res = await updateDoctorInfo(param);
      if (res.code == 200) {
        Message({
          message: "操作成功",
          type: "success",
        });
        this.getList();
      } else {
        Message({
          message: res.message,
          type: "error",
        });
      }
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {},
    //取消
    handleQX() {
      this.dialogFormVisible2 = false;
      this.getList();
    },
    handleInquiryPriceCancel() {
      this.isActiveDialog = false;
    },
    getList(type) {
      console.log("getList触发");
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      getDoctorsListNew(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            console.log(1111111111111, data);
            let _data = data.data;
            this.total = _data.total;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    }, // 如果draw数据更新,回调getList()刷新数据

    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "doctorManage",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.doctorManage;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
      if (this.multipleSelectionPres.length == 1) {
        this.curButtonShow = this.multipleSelectionPres[0].pres_status_name;
        this.curPresStatus = this.multipleSelectionPres[0].pres_status;
      } else {
        this.curButtonShow = "";
        this.curPresStatus = 0;
      }
      //将this.multipleSelection传给子组件
      // console.log(this.$refs);
      // this.$refs.CDMSelectionOfTheService.multipleSelection = this.multipleSelection;
    },
    handleCustomerDetail(row, e) {
      console.log(row, e);
      if (e == "doctor_name") {
        this.getInfo(row.doctors_id);
      } else if (e == "professionalName") {
        this.handleSetInquiryPrice(row);
      } else if (e == "show_sort") {
        this.handleSort(row);
      } else if (e == "is_bind") {
        this.handleAssistant(row);
      }
    },
    //医助
    handleAssistant(row) {
      this.doc_id = row.doctors_id;
      this._getAssistantList();
    },
    _getAssistantList() {
      getAssistants(this.doc_id, this.bindQuery).then((res) => {
        this.dialogAssistant = true;
        this.assistants = res.data.data;
        this.bindTotal = res.data.total;
        this._selectRow();
      });
    },
    _selectRow() {
      let list = [];
      this.assistants.forEach((row) => {
        if (row.o_id == 0) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    //修改排序
    haneldSortSubmit(row) {
      console.log(row);
      let param = {
        show_sort: row.show_sort,
        id: row.doctors_id,
      };
      updateDoctorInfo(param).then((res) => {
        if (res.code == 200) {
          Message({
            message: "操作成功",
            type: "success",
          });
          this.dialogFormVisible2 = false;
          this.getList();
        }
      });
    },
    //排序
    handleSort(row) {
      console.log(row);
      this.doctorList = row;
      this.dialogFormVisible2 = true;
    },
    //就诊设置确认按钮
    submitInquiryPriceForm() {
      var reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (
        !reg.test(this.picPrice.commonConsultation) ||
        !reg.test(this.videoPrice.commonConsultation)
      ) {
        this.$message({
          type: "error",
          message: "输入错误, 常规复诊错误",
        });
      } else if (
        !reg.test(this.picPrice.FirstConsultation) ||
        !reg.test(this.videoPrice.FirstConsultation)
      ) {
        this.$message({
          type: "error",
          message: "输入错误, 首次复诊错误",
        });
      } else if (this.picPrice.pic_time < 1 || this.videoPrice.video_time < 1) {
        this.$message({
          type: "error",
          message: "输入错误, 接单次数需要大于0",
        });
      } else {
        console.log("postUpdateDiagnosisSetting");
        this.isActiveDialog = false;
        var data = {
          video_time_type: this.videoPrice.video_time_type,
          pic_time_type: this.picPrice.pic_time_type,
          video_status: this.videoPrice.video_status,
          pic_status: this.picPrice.pic_status,
          first_video_price: this.videoPrice.FirstConsultation,
          first_pic_price: this.picPrice.FirstConsultation,
          pic_price: this.picPrice.commonConsultation,
          video_price: this.videoPrice.commonConsultation,
          pic_time: this.picPrice.pic_time,
          video_time: this.videoPrice.video_time,
        };
        console.log(data);
        postUpdateDiagnosisSetting(data, this.curDoctor.doctors_id).then(
          (res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          }
        );
      }
      // this.isActiveDialog = false
    },
    //就诊设置
    async handleSetInquiryPrice(row) {
      console.log("设置问诊价格");
      this.curDoctor = row;
      try {
        var res = await getDiagnosisSetting(row.doctors_id);
        console.log(res);
        if (res.code == 200) {
          var _data = res.data;
          this.picPrice = {
            FirstConsultation: _data.first_pic_price * 1,
            commonConsultation: _data.pic_price * 1,
            pic_time_type: _data.pic_time_type, // 视频问诊时间格式类型 0分 1时 2天
            pic_time: _data.pic_time,
            pic_status: _data.pic_status,
          };
          this.videoPrice = {
            FirstConsultation: _data.first_video_price * 1,
            commonConsultation: _data.video_price * 1,
            video_time_type: _data.video_time_type, // 视频问诊时间格式类型 0分 1时 2天
            video_time: _data.video_time,
            video_status: _data.video_status,
          };
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.picPrice = {
            FirstConsultation: 0.01,
            commonConsultation: 0.01,
            pic_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
            pic_time: 1,
            pic_status: 1,
          };
          this.videoPrice = {
            FirstConsultation: 0.01,
            commonConsultation: 0.01,
            video_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
            video_time: 1,
            video_status: 1,
          };
        }
      } catch (e) {
        console.log(e);
      }
      this.isActiveDialog = true;
    },
    //查看医生详情面板
    getInfo(id) {
      console.log(id);
      let params = {
        id: id,
      };
      getDoctorsDetails(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.info = {
              ..._data,
            };
            this.dialogTableVisible = true;
            setTimeout(() => {
              //解决id异步获取问题
              this.$refs.physicianDetail.id = id;
              console.log(this.$refs.physicianDetail, "3534543");
            }, 100);
          }
          if (this.info.depart_id) {
            this.info.depart_id = Number(this.info.depart_id);
          }
          this.fullscreenLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
</style>
